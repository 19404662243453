<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        a) If you have a
        <number-value :value="mass" unit="\text{g}" />
        sample of
        <chemical-latex :content="atomName.toLowerCase()" />, how many <b>moles</b> of the element
        do you have?
      </p>

      <calculation-input
        v-model="inputs.mol"
        class="mb-5"
        prepend-text="$\text{Amount:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) How many <b>atoms</b> of
        <chemical-latex :content="atomName.toLowerCase()" />
        would be in the sample described in part a)?
      </p>

      <calculation-input
        v-model="inputs.atoms"
        show-helpful-hint
        prepend-text="$\text{Amount:}$"
        append-text="$\text{atoms}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question376',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        mol: null,
        atoms: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    chemicalElement() {
      return this.taskState.getValueBySymbol('element').content;
    },
    atomName() {
      return this.chemicalElement.name;
    },
  },
};
</script>
